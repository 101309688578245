<template>
  <ion-page>
    <ion-tabs>
      <ion-router-outlet></ion-router-outlet>

      <ion-tab-bar slot="bottom">

        <ion-tab-button tab="profile" @click="gotoProfile" >
          <ion-icon :icon="personCircleOutline"></ion-icon>
          <ion-label>Profil</ion-label>
        </ion-tab-button>

        <ion-tab-button tab="clocking" @click="gotoClocking" >
          <ion-icon :icon="locationOutline"></ion-icon>
          <ion-label>Check-In</ion-label>
        </ion-tab-button>

        <ion-tab-button tab="schedule" @click="gotoSchedule">
          <ion-icon :icon="calendarOutline" />
          <ion-label>Aufträge</ion-label>
        </ion-tab-button>

        <ion-tab-button tab="other" @click="gotoOther">
          <ion-icon :icon="layersOutline" />
          <ion-label>Anderes</ion-label>
        </ion-tab-button>

      </ion-tab-bar>
    </ion-tabs>

  </ion-page>
</template>

<script>
import { IonTabBar, IonTabButton, IonTabs, IonLabel, IonIcon, IonPage, IonRouterOutlet } from '@ionic/vue';
import { personCircleOutline, locationOutline, calendarOutline, layersOutline } from 'ionicons/icons';

export default {
  name: 'Tabs',
  components: { IonLabel, IonTabs, IonTabBar, IonTabButton, IonIcon, IonPage, IonRouterOutlet },
  setup() {
    return {
      personCircleOutline,
      locationOutline,
      calendarOutline,
      layersOutline
    }
  },
  computed:{

  },
  methods:{
    gotoProfile(){
      if (this.$route.path !== "/tabs/profile")
        this.$router.push("/tabs/profile");
    },
    gotoClocking(){
      if (this.$route.path !== "/tabs/clocking")
        this.$router.push("/tabs/clocking");
    },
    gotoSchedule(){
      if (this.$route.path !== "/tabs/schedule")
        this.$router.push("/tabs/schedule");
    },
    gotoOther(){
      if (this.$route.path !== "/tabs/other")
        this.$router.push("/tabs/other");
    }
  }

}
</script>