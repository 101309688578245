import axios from "axios";
import moment from "moment-timezone";



export default function requestShifts(region, store, persistentStore, callback = () => {}){

    axios.get(`https://aarrow.app/development/secure/newApi/index.php/shift/withRegion/${region}`)
        .then(response => {
                let tempShifts = response.data.payload;
                //tempShifts = tempShifts.filter(shift => new Date(shift.date).getTime() - new Date().getTime() > -86400 * 1000 * 14);
                const activeShiftId = store.state.activeShiftId;

                const activeShift = store.state.shifts.find(s => s.id === activeShiftId);

                if (activeShiftId === undefined || activeShiftId === null || activeShift === undefined || activeShift === null || activeShiftId === -1 || activeShiftId === "" || !Object.prototype.hasOwnProperty.call(activeShift, "date") || moment().tz(activeShift.timezone).format("Y-MM-DD") !== activeShift.date){

                    const personalShifts = tempShifts.filter(shift => shift.employeeEmail.toLowerCase() === store.state.userEmail.toLowerCase());

                    if (personalShifts.length){


                        const candidateShifts = personalShifts.filter(shift => moment().tz(shift.timezone).format("Y-MM-DD") === shift.date);
                        if (candidateShifts.length)
                            store.commit('updateActiveShiftId', candidateShifts[0].id)
                        else
                            store.commit('updateActiveShiftId', null)

                    }

                }

                store.commit('addMultipleShifts', tempShifts)

                persistentStore.get('applicationData').then(data => {
                    if (!data)
                        return;

                    data.shifts = tempShifts;

                    persistentStore.set('applicationData', data);
                })
            console.log(tempShifts)
                callback();
            }
        )

}