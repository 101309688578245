<template>
  <ion-header>

  </ion-header>
  <ion-content>
      <litepie-datepicker ref="datepicker" v-show="showPicker"
          overlay
          as-single
          use-range
          :auto-apply="false"
          v-model="dateValue"
      ></litepie-datepicker>
  </ion-content>
</template>

<script>
import { ref } from 'vue';

import { IonContent, IonHeader} from "@ionic/vue";
import {modalController} from "@ionic/vue";
import moment from "moment-timezone";

export default {
  name: "FilterModal",
  props: ['startDate', 'endDate'],
  components: { IonContent, IonHeader},
  data(){

    return{
      isMounted: false,
      showPicker: false
    }
  },
  setup(props) {

    const dateValue = ref({
      startDate: moment(props.startDate).format("YYYY-MM-DD HH:mm:ss"),
      endDate: moment(props.endDate).format("YYYY-MM-DD HH:mm:ss")
    });

    return {
      dateValue
    }
  },
  computed:{
    datePickerHidden(){
      if(!this.isMounted)
        return
      return !this.$refs.datepicker.isShow
    }
  },
  mounted() {
    this.$refs.datepicker.show()
    this.isMounted = true
    setTimeout(() => this.showPicker = true, 500)
  },
  watch:{
    datePickerHidden(newValue){
      if(newValue)
        modalController.dismiss(this.dateValue)
    }
  },
}
</script>

<style scoped>

</style>