<template>
  <ion-page>
    <ion-header>
      <ion-toolbar mode="ios">
        <ion-segment slot="secondary" color="light" mode="ios" v-bind:value="shiftType" @ionChange="shiftTypeChanged($event)" style="height: 42px; margin-top: 5px; margin-bottom: 5px">
          <ion-segment-button  value="Personal" mode="ios">
            Deine
          </ion-segment-button>
          <ion-segment-button  value="All" mode="ios">
            Offene
          </ion-segment-button>
        </ion-segment>
        <ion-searchbar placeholder = "Suchen" v-bind:value="searchString" @ionClear="cancelSearch" @input="updateSearchbar($event)" color="light" style="padding-bottom: 0; padding-top: 0" animated>

        </ion-searchbar>
        <ion-buttons slot="primary" style="height: 52px">
          <ion-button @click="showFilters">
            <ion-icon class = "whiteIcon" slot="icon-only" :icon="funnelOutline"></ion-icon>
          </ion-button>
        </ion-buttons>
      </ion-toolbar>
    </ion-header>
    <ion-content class="brightBackground">
      <anchor :start-date="startDate" :end-date="endDate"  v-bind:shiftType="shiftType" v-bind:search-string="searchString" ref="content"></anchor>
    </ion-content>
  </ion-page>

</template>

<script>
import Anchor from "../components/ScheduleTab/Anchor";
import {IonButtons, IonContent, IonHeader, IonPage, IonToolbar, IonSegment, IonSegmentButton, IonIcon, IonButton ,IonSearchbar, modalController} from "@ionic/vue";
import {funnelOutline} from 'ionicons/icons';
import FilterModal from "../components/ScheduleTab/FilterModal";
import moment from "moment-timezone";
import PullToRefresh from "pulltorefreshjs";
import requestShifts from "../utils/requestShifts";

export default  {
  name: 'TabSchedule',
  components: {
    Anchor,
    IonPage,
    IonHeader,
    IonToolbar,
    IonContent,
    IonButtons,
    IonSegment,
    IonSegmentButton,
    IonSearchbar,
    IonIcon,
    IonButton
  },
  data(){
    return {
      startDate: moment(moment.now() - 86400 * 14 * 1000).format("YYYY-MM-DD"),
      endDate: moment(moment.now() + 86400 * 28 * 1000).format("YYYY-MM-DD"),
    }
  },
  setup(){
    return {
      funnelOutline
    }
  },
  computed:{
    shiftType(){
      return this.$store.state.scheduleState.shiftType;
    },
    searchString(){
      return this.$store.state.scheduleState.searchString;
    }
  },
  methods:{
    shiftTypeChanged(event){
      this.$store.commit('updateShiftType', event.detail.value);
    },
    updateSearchbar(event){
      this.$store.commit('updateSearchString', event.srcElement.value);
    },
    cancelSearch(){
      this.$store.commit('updateSearchString', "");
    },
    showFilters(){
      this.openModal()
    },
    async openModal(){
        const modal = await modalController.create({
          component: FilterModal, //Modal is name of the component to render inside ionic modal,
          showBackdrop: true,
          cssClass: 'filterModal',
          componentProps: {startDate: this.startDate,endDate: this.endDate}
        });
        modal.present();
        modal.onDidDismiss().then(data => {
          if(Object.keys(data.data).length){
            this.changeDates(data.data.startDate, data.data.endDate)
          }
        })
    },
    changeDates(startDate, endDate){

      this.startDate = moment(startDate).format("YYYY-MM-DD")
      this.endDate = moment(endDate).format("YYYY-MM-DD")
    }
  },
  mounted() {

      const mainElement = this.$refs.content.$el
      const store = this.$store
      PullToRefresh.init({
        mainElement: mainElement.children[1].children[0],
        triggerElement: mainElement,
        onRefresh() {
          requestShifts(store.state.userRegion, store, store.state.$ionicStorage);
        },
        shouldPullToRefresh: () => {
          if (mainElement.children[0] && mainElement.children[1].children[0])
            return mainElement.children[0].getBoundingClientRect().y === mainElement.children[1].children[0].getBoundingClientRect().top
          else
            return false;
        }
      });




  }

}
</script>

<style>
.filterModal .modal-wrapper{
  width:80%;
  height:70%;

  border: 2px solid white;
  border-radius: 10px;
  overflow-y: hidden;
}

ion-searchbar input{
  padding-left: 30px !important;
}
ion-searchbar ion-icon{
  margin-left: 0 !important;

}


ion-header{
  --background: black;
}

ion-segment{
  border: 1px solid white
}


.segment-button-checked{
  color:black;
}


.whiteIcon{
  color: white;
}



</style>


