import moment from "moment";
import momentDurationFormatSetup from "moment-duration-format";

momentDurationFormatSetup(moment)

function millisecondsDifferenceToHoursMinuteSecondString(milliseconds){
    return moment.duration(milliseconds).format("HH:mm:ss", { trim: false });
}

function millisecondsBetweenTimes(time1, time2){
    return Math.abs(moment(time1, "HH:mm:ss").diff(moment(time2, "HH:mm:ss")));
}

function timeToAmPmFormat(time){

    if (time === "" || time === "-" || time === "00:00:00")
        return "-";

    return moment(timeToMilliSeconds(time)).format("HH:mm")
}

function timeToMilliSeconds(time){

    return moment(time, 'HH:mm:ss').valueOf()

}

function dateToAmericanFormat(date){

    return moment(date).format("DD.MM.YYYY")

}

function dateToAmericanFormatWithWeekday(date){
    moment.locale('de')

    return moment(date).format("dddd DD.MM.YYYY")

}

export{millisecondsDifferenceToHoursMinuteSecondString, millisecondsBetweenTimes, timeToMilliSeconds, timeToAmPmFormat, dateToAmericanFormat, dateToAmericanFormatWithWeekday}