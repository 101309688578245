<template>
  <svg version="1.1" id="arrow" :class="activeClass" xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink" x="0px" y="0px"
       viewBox="0 0 530 180" style="" xml:space="preserve" v-on:click="showInfo" :style="`transform: rotate(${rotation}deg);`" ref="arrowSvg">
    <g>
      <!--<text transform="matrix(1 0 0 1 25.4941 72.96)" class="st5 st6" >{{weekday}}</text>-->
        <image transform="matrix(1 0 0 1 -185 20.0)" width="100%" height="120px" :href="imagePath" />
</g>
    <text transform="matrix(1 0 0 1 159.4199 32.2285)" class="st3 st7 st8 boldbig" >{{weekday}} {{ dateShort }}</text>
    <text transform="matrix(1 0 0 1 159.4199 58.2285)" class="st3 st7 st8" >{{shift.employeeName ? shift.employeeName : shift.serviceType}}</text>
    <text transform="matrix(1 0 0 1 159.4199 81.1846 )" class="st3 st7 st8" >{{shift.location}}</text>
      <text transform="matrix(1 0 0 1 159.4199 104.1406)" class="st3 st7 st8" >{{shiftTimes}}</text>
      <text transform="matrix(1 0 0 1 159.4199 127.0967)" class="st3 st7 st8" >{{shift.address.slice(shift.address.lastIndexOf(",")+1)}}</text>

</svg>
</template>

<script>

import moment from "moment-timezone";
import * as momentUtils from "../../utils/momentUtils";

export default {
  name: "ShiftArrow",
  props: {
    'shift': Object,
  },
  data(){

    return {
      rotation: 0
    }

  },
  computed: {

    auditString(){
        if (this.shift.audits.length){
            let audit = this.shift.audits[0]
            let total = 0
            for (let key in audit){
                if (key === "shift_id" || key === "client_complaint" || key === "discipline_required" ||
                    key === "exceptional_performance" || key === "inclement_weather" || key === "sign_condition" ){
                    continue
                }
                if (typeof audit[key] === "number"){
                    total += audit[key]
                }
            }
            return total
        }
        return "-"
    },

    hasStarted(){
        return this.shift.clockInTime !== "00:00:00"
    },
    shiftTimes(){
        if (!this.hasStarted)
          return momentUtils.timeToAmPmFormat(this.shift.startTime) + " - " + momentUtils.timeToAmPmFormat(this.shift.endTime);
        else
            return momentUtils.timeToAmPmFormat(this.shift.svcStartTime) + " - " + momentUtils.timeToAmPmFormat(this.shift.svcEndTime);

    },
    weekday(){
      moment.locale('de')
      return moment(this.shift.date).format("ddd")
    },
    dateShort(){
      return moment(this.shift.date).format("DD.MM")
    },
    imagePath(){
        if (this.shift.images.length){
            return this.shift.images[0].path
        }

        return this.getIconForStatus(this.shift.status)
    },
    arrowBackgroundColor(){
      switch(this.shift.status){
        case "3. Shift Accepted":
        case "5. Morning Confirmed":
        case "6. On-Site Confirmation":
        case "7. Shift Completed":
        case "*8. APPROVE Shift Times":
        case "9. Shift Approved":
        case "2. SignSpinner bestätigt":
        case "3. Reise bestätigt":
        case "4. Sign bestätigt":
        case "5. Planung abgeschlossen":
        case "6. Eingecheckt":
        case "8. Abgeschlossen":

          return "green";
        case "3. Shift Rejected":
        case "5. CALL to CONFIRM":
        case "3. CALL to SCHEDULE":
        case "6. CALL to VERIFY":
        case "*CANCEL Shift":
        case "Shift Cancelled":
        case "5. Called Off":
        case "7. Nicht Eingecheckt":
          return "red";
        case "2. Invite Sent":
        case "4. MC Requested":
        case "1. Offen":
          return "yellow";
        default:
          return "grey"
      }
    },
    activeClass(){
      switch(this.arrowBackgroundColor){
        case "green":
          return "active";
        case "yellow":
          return "pending";
        case "red":
          return "cancelled";
        default:
          return "done"
      }
    }
  },
  methods:{


    getIconForStatus(status){
    let icon;

        let icons = {
            emergencyred:"https://aarrow.app/development/secure/images/shiftAlert.png",
            greencheck:"https://aarrow.app/development/secure/images/shiftCompleted.png",
            greenphone:"https://aarrow.app/development/secure/images/morningConfirmed.png",
            redphone:"https://aarrow.app/development/secure/images/callToConfirm.png",
            redx:"https://aarrow.app/development/secure/images/shiftCanceled.png",
            shiftjohnny:"https://aarrow.app/development/secure/images/shiftRunning.png",
            yellowphone:"https://aarrow.app/development/secure/images/mcRequested.png",
            laemergencyred:"https://aarrow.app/development/secure/images/emergencyred.png",
            lagreencheck:"https://aarrow.app/development/secure/images/greencheck.png",
            lagreenphone:"https://aarrow.app/development/secure/images/greenphone.png",
            laredphone:"https://aarrow.app/development/secure/images/redphone.png",
            laredx:"https://aarrow.app/development/secure/images/redx.png",
            lashiftjohnny:"https://aarrow.app/development/secure/images/shiftjohnny.png",
            layellowphone:"https://aarrow.app/development/secure/images/yellowphone.png",
            blackWhiteJohnny:"https://aarrow.app/development/secure/images/shiftUnknown.png",
            silhouetteJohnny: "https://aarrow.app/development/secure/images/map-icon.png",
            star: "https://aarrow.app/development/secure/images/star.svg",
            johnnyAarrow: "https://aarrow.app/development/secure/images/JohnnyAarrow.jpg",
            pointyJohnnyAarrow: "https://aarrow.app/development/secure/images/map-icon.png",
            acceptJohnny: "https://aarrow.app/development/secure/images/acceptJohnny.png",
            rejectJohnny: "https://aarrow.app/development/secure/images/rejectJohnny.png",
            pendingJohnny: "https://aarrow.app/development/secure/images/pendingJohnny.png",
        };

    switch(status){

        case "2. Invite Sent":
            icon = icons.pendingJohnny
            break;
        case "3. Shift Accepted":
            icon = icons.acceptJohnny
            break;
        case "3. Shift Rejected":
            icon = icons.rejectJohnny
            break;
        case "6. On-Site Confirmation":
            icon = icons.shiftjohnny;
            break;
        case "6. CALL to VERIFY":
            icon = icons.emergencyred;
            break;
        case "5. Morning Confirmed":
            icon = icons.greenphone;
            break;
        case "4. MC Requested":
            icon = icons.yellowphone;
            break;
        case "5. CALL to CONFIRM":
            icon = icons.redphone;
            break;
        case "7. Shift Completed":
            icon = icons.greencheck;
            break;
        case "*8. APPROVE Shift Times":
            icon = icons.greencheck;
            break;
        case "9. Shift Approved":
            icon = icons.greencheck;
            break;
        case "*CANCEL Shift" :
            icon = icons.redx;
            break;
        case "Shift Cancelled":
            icon = icons.redx;
            break;
        default:
            icon = icons.blackWhiteJohnny
    }

    return icon;
},

    showInfo(){
      this.rotate();
      setTimeout(() => {
        this.$router.push(`/tabs/schedule/${this.shift.id}`);
      }, 1000)

    },
    rotate(){
      this.$refs.arrowSvg.style.transitionDuration = "1s";
      this.rotation += 360

    },
      isNumeric(str) {
          if (typeof str != "string") return false // we only process strings!
          return !isNaN(str) && // use type coercion to parse the _entirety_ of the string (`parseFloat` alone does not do this)...
              !isNaN(parseFloat(str)) // ...and ensure strings of whitespace fail
      }


  },


}

</script>

<style scoped>

    svg{
      transition-duration: 1s;

    }





    /* You can rename the status accordingly */
    /*Green*/

    .active {
      background-image: url("../../assets/activeAarrow.svg");
    }
    /*Yellow*/
    .pending {
      background-image: url("../../assets/pendingAarrow.svg");
    }
    /*Red*/
    .cancelled {
      background-image: url("../../assets/cancelledAarrow.svg");
    }
    /*Blue*/
    .done {
      background-image: url("../../assets/doneAarrow.svg");
    }
    #arrow .st1{fill:#cacaca;}
    #arrow .st0{fill:#FFFFFF;stroke:#999999;stroke-miterlimit:10;}
    #arrow .st2{opacity:0.1;clip-path:url(#SVGID_2_);}
    #arrow .st3{fill:#FFFFFF;}
    #arrow .st4{fill:#DDDDDD;}
    #arrow .st5{font-family:sans-serif;}
    #arrow .st6{font-size:24px;}
    #arrow .st7{font-family:sans-serif;}
    #arrow .st8{font-size:18px;}
    #arrow .shadow{transform: scale(0.56);fill: #e1e1e1;}
    .boldbig{
        font-weight: bold;

    }

    image{     clip-path: inset(0% round 50%); }
</style>