<template>

  <ion-app>
    <ion-router-outlet />
  </ion-app>
</template>

<script>
import {IonApp, IonRouterOutlet, isPlatform, toastController} from '@ionic/vue';
import { defineComponent } from 'vue';
import moment from "moment-timezone";
import { App } from '@capacitor/app';
import requestShifts from "./utils/requestShifts";
import axios from "axios";
import requestUserdata from "./utils/requestUserdata";


export default defineComponent({



  name: 'App',
  components: {
    IonApp,
    IonRouterOutlet
  },
  computed:{

    personalShifts(){
        console.log("personal", this.$store.state.shifts.filter(shift => shift.employeeEmail.toLowerCase() === this.$store.state.userEmail.toLowerCase()))
      return this.$store.state.shifts.filter(shift => shift.employeeEmail.toLowerCase() === this.$store.state.userEmail.toLowerCase());
    },
    candidateShifts(){
      if (!this.personalShifts.length)
        return []

      return this.personalShifts.filter(shift => moment().tz(shift.timezone).format("Y-MM-DD") === shift.date);
    },
    futurePersonalShifts(){
      const todayMoment = moment(moment().format("Y-MM-DD"), "Y-MM-DD")
      return this.personalShifts.filter(shift => moment(shift.date, "Y-MM-DD").diff(todayMoment) >= 0)
    }


  },
  methods:{
    initialiseMapsApi(){
      if(window.google && window.google.maps){
        return;
      }
      const googleMapScript = document.createElement("SCRIPT");

      googleMapScript.setAttribute(
          "src",
          `https://maps.googleapis.com/maps/api/js?key=AIzaSyCnxwfPZZMEHzz1ufQPXCJufrwZfQ3mkL0&libraries=visualization`
      );
      googleMapScript.setAttribute("defer", "");
      googleMapScript.setAttribute("async", "");
      document.head.appendChild(googleMapScript);
    },
    async checkActionRequired(){

      const actionNeededCount = this.futurePersonalShifts.filter(shift => shift.status === "2. Invite Sent" || shift.status === "3. CALL to SCHEDULE" ||  shift.status === "4. MC Requested" || shift.status === "5. CALL to CONFIRM" || shift.status === "6. CALL to VERIFY").length
      if(!actionNeededCount)
        return
      const toast = await toastController
          .create({
            message: `${actionNeededCount} ${actionNeededCount > 1 ? "shifts" : "shift"} ${actionNeededCount > 1 ? "require" : "requires"} action by you`,
            position: 'top',
            duration: 5000,
            buttons: [
              {
                side: 'start',
                text: 'Review',
                handler: () => {
                  this.$router.push("/actions")
                }
              }

            ]
          })
      await toast.present()
    },
     async addListeners() {


    },
    async registerNotifications() {

    },

    async getDeliveredNotifications(){

    },

    async checkNotSignedShifts(){
      let response = await axios.get("https://aarrow.app/development/secure/newApi/index.php/trackingShift/unsigned", {})
      let data = response.data.payload;

      if (data.length){
        this.$router.push(`/tabs/clocking/sign-out/${data[0]}`);
      }

    },
    checkActive(){
        let userData = this.$store.state.userData;
        console.log(userData)
        if (!Object.prototype.hasOwnProperty.call(userData, "active")){
          return
        }
        console.log(userData.region)
        if (userData.active === false && userData.region !== "GER"){
          if (this.$router.currentRoute.value.name !== "Login"){
            this.logout();
          }
        }else{
          requestShifts(this.$store.state.userRegion, this.$store, this.$store.state.$ionicStorage, this.checkActionRequired);
          //this.checkNotSignedShifts()

        }

    },
    async logout() {

      await this.$ionicStorage.clear()
      await this.$store.commit('updateActiveShiftId', null)
      await this.$router.replace("/login")
      const toast = await toastController
          .create({
            message: `Automatic logout because your account was marked as inactive. Please contact your manager to get activated`,
            position: 'top',
            duration: 10000,

          })
      await toast.present()
    },

  },

  mounted(){



    App.addListener('appStateChange', ({ isActive }) => {
      if (isActive && !this.$store.state.updateLock){
        requestUserdata(this.$store, this.$store.state.$ionicStorage, this.checkActive);
      }

    });
    if(isPlatform('ios') || isPlatform('android')){
      this.addListeners();
      this.registerNotifications();
    }

  },
  created() {
    this.initialiseMapsApi();

  }

});
</script>