<template>
  <ion-page >
    <ion-header>
      <ion-toolbar mode="ios">
        <ion-title>Anderes</ion-title>

      </ion-toolbar>
    </ion-header>
    <ion-content :fullscreen="true" class="brightBackground">
      <ion-grid >
        <ion-row class="titleRow">
          <ion-col>
            <ion-card-title >Persönlich</ion-card-title>
          </ion-col>
        </ion-row>
        <ion-row>

          <ion-col v-for="element in personalRow" :key="element.title">
            <ion-card button @click="element.click">
              <img :src="element.src"  alt=""/>
              <ion-card-header>
                <ion-card-title>
                  {{ element.title }}
                </ion-card-title>
              </ion-card-header>
            </ion-card>
          </ion-col>
        </ion-row>
        <ion-row class="titleRow">
          <ion-col>
            <ion-card-title>Infos & Videos</ion-card-title>
          </ion-col>
        </ion-row>
        <ion-row >
          <ion-col v-for="element in videoRow" :key="element.title">
            <ion-card button @click="element.click">
              <img :src="element.src"  alt=""/>
              <ion-card-header>
                <ion-card-title >{{element.title}}
                </ion-card-title>
              </ion-card-header>
            </ion-card>
          </ion-col>
        </ion-row>
        <ion-row class="titleRow">
          <ion-col>
            <ion-card-title>App</ion-card-title>
          </ion-col>
        </ion-row>
        <ion-row>
          <ion-col v-for="element in hrRow" :key="element.title">
            <ion-card button @click="element.click">
              <img :src="element.src"  alt=""/>
              <ion-card-header>
                <ion-card-title>
                  {{element.title}}
                </ion-card-title>
              </ion-card-header>
            </ion-card>
          </ion-col>
        </ion-row>
      </ion-grid>
      <ion-select v-model="userRegion" style="display:none" ref="regionSelect">
        <ion-select-option v-for="region in regions" v-bind:key="region.longName" v-bind:value="region.shortName">{{region.longName}}</ion-select-option>
      </ion-select>
    </ion-content>

  </ion-page>

</template>

<script>
import { IonGrid, IonRow, IonCol, IonCard, IonCardHeader, IonCardTitle ,IonPage, IonHeader, IonToolbar, IonTitle, IonContent, IonSelect, IonSelectOption, toastController } from '@ionic/vue';
import { Device } from '@capacitor/device';


import finances from "../assets/tempImages/finances.png"
import info from "../assets/tempImages/info.png"
import inbox from "../assets/tempImages/inbox.png"

import youtube from "../assets/tempImages/youtube.png"

import logout from "../assets/tempImages/logout.png"

import { Browser } from '@capacitor/browser';
import axios from "axios";
import requestShifts from "../utils/requestShifts";
import Axios from "axios";
import {getMessaging, getToken} from "firebase/messaging";
import {initializeApp} from "firebase/app";
import {getAnalytics} from "firebase/analytics";


const firebaseConfig = {
  apiKey: "AIzaSyBlkFLtkNUJUVOAG_syFWgPDnjRkAWmapA",
  authDomain: "aarrowandroidapp.firebaseapp.com",
  databaseURL: "https://aarrowandroidapp.firebaseio.com",
  projectId: "aarrowandroidapp",
  storageBucket: "aarrowandroidapp.appspot.com",
  messagingSenderId: "227795247781",
  appId: "1:227795247781:web:54a30ca6d53fe99137234c",
  measurementId: "G-J59MPM3H14"
};

export default {
  name: 'TabOther',
  components: {
    IonGrid,
    IonRow,
    IonCol,
    IonCard,
    IonCardHeader,
    IonCardTitle,
    IonHeader,
    IonToolbar,
    IonTitle,
    IonContent,
    IonPage,
    IonSelect,
    IonSelectOption
  },
  data() {
    return {
      userRegion: '',
      regions: [],
      showRegionSelect: false,
      personalRow: [
        {
          title: "Finanzen",
          src: finances,
          click: this.showFinances
        },
        {
          title: "Nachrichten",
          src: inbox,
          click: this.showNotifications
        },

      ],
      videoRow: [
        {
          title: "Spinformation",
          src: info,
          click: this.showSpinformation
        },
        {
          title: "YouTube",
          src: youtube,
          click: this.showYouTube
        },
      ],
      hrRow: [
        {
          title: "Logout",
          src: logout,
          click: this.logout
        },
        {
          title: "Debug Report",
          src: info,
          click: this.debugReport
        },
        {
          title: "Notification Permission",
          src: info,
          click: this.requestNotificationPermission
        },


      ]
    }
  },
  computed:{
    isManager(){
      return this.$store.state.isManager;
    }
  },
  methods: {
    showFinances(){
      this.$router.push("/tabs/external/finances");
    },
    showSpinformation(){
      this.$router.push("/tabs/drive/spinformation");
    },
    showCorner() {
      this.$router.push("/tabs/drive/corner");
    },
    showCoaching() {
      this.$router.push("/tabs/drive/coaching");
    },
    showHighlight() {
      this.$router.push("/tabs/drive/highlight");
    },
    showWSSC() {
      this.$router.push("/tabs/drive/wssc");
    },
    showYouTube() {
      Browser.open({url: 'https://www.youtube.com/user/SignSpinMarketing'})
    },
    showAudits() {
      this.$router.push("/tabs/external/audit");
    },
    showLeads() {
      this.$router.push("/tabs/external/leads");
    },
    showRecruits() {
      this.$router.push("/tabs/external/recruits");
    },
    showMapView() {
      this.$router.push("/tabs/external/map");
    },
    showMWs() {
      this.$router.push("/tabs/external/mw");
    },
    showNotifications(){
      this.$router.push("/tabs/external/inbox");
    },
    async logout() {
      await this.$ionicStorage.clear()
      await this.$store.commit('updateActiveShiftId', null)
      await this.$router.replace("/login")
    },
    showShop() {
      Browser.open({url: 'https://aarrow.shop'})
    },
    async debugReport(){
      const deviceInfo = await Device.getInfo();
      const applicationData = await this.$store.state.$ionicStorage.get('applicationData');
      console.log(applicationData)
      console.log(deviceInfo);
      console.log(JSON.parse(JSON.stringify(this.$store.state)))
      for (let key in this.$store.state){
        console.log(key, this.$store.state[key])
      }
    },

    showRegions() {

      axios.get(`https://aarrow.app/development/secure/newApi/index.php/user/regions/${this.$store.state.userEmail}`)
          .then(response => {
            this.regions = response.data.payload;
            setTimeout(() => {
              this.$refs.regionSelect.$el.click();
              this.showRegionSelect = false;
            }, 0)
          });
    },

    requestNotificationPermission(){
      Notification.requestPermission().then((permission) => {
        if (permission === 'granted') {
          console.log('Notification permission granted.');
          const app = initializeApp(firebaseConfig);
          getAnalytics(app);
          const messaging = getMessaging(app);

          getToken(messaging, {vapidKey: "BPPr2g_TTa7fIOopaW2xAmpAudqqXVCFTksMkeCJjm-KHK9CztulW3mCei6rPJLUVbutVrhmXZ2SmrDLXpfvAyY"}).then((currentToken) => {
            if (currentToken) {
              console.log(currentToken)
              // Send the token to your server and update the UI if necessary
              // ...
              axios.patch("https://aarrow.app/development/secure/newApi/index.php/user/fcmToken", {token: currentToken}, {}, data => {
                console.log(data);
              })
            }
          });

        }

        // ...

      }).catch((err) => {
        console.log('An error occurred while retrieving token. ', err);
        // ...
      });    }
  },
  watch: {
    userRegion(region) {
      axios.get(`https://aarrow.app/development/secure/newApi/index.php/user/jwt/${region}`)
          .then(response => {
            const jwt = response.data.payload[0];
            this.$store.commit('updateJWT', jwt);
            this.$ionicStorage.set("applicationData", {
              jwt: jwt,
              shifts: [],
              activeShiftId: -1
            }).then(() => {
              Axios.defaults.headers.common['Authorization'] = `Bearer ${jwt}`;
              requestShifts(region, this.$store, this.$store.state.$ionicStorage);
              toastController
                  .create({
                    header: 'Region Changed',
                    message: "New Region is: " + this.regions.find(r => r.shortName === region)["longName"],
                    position: 'top',
                    duration: 2000,

                  }).then(toast => {toast.present()})
            });
          });
    }
  }
}
</script>

<style>

ion-row{
  display: flex;
  flex-wrap: nowrap;
  overflow-x: scroll;
}
ion-card{
  margin: 0;

}
ion-card-title{
  font-size: 16px;
}


ion-row ion-col{
  width: 40%;
  min-width: 40%
}
ion-row img {
  height: 100px;
  object-fit: fill;
  width:100%;
}

.titleRow{
  margin-top:3%;
}
.titleRow  *{
  font-size: 1.3em;
  font-weight: bold;

}

</style>