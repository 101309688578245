import { createApp } from 'vue'
import App from './App.vue'
import router from './router';
import store from "./store";
import { IonicVue } from '@ionic/vue';
import Axios from 'axios';
/* Core CSS required for Ionic components to work properly */
import '@ionic/vue/css/core.css';
import veProgress from "vue-ellipse-progress";

/* Basic CSS for apps built with Ionic */
import '@ionic/vue/css/normalize.css';
import '@ionic/vue/css/structure.css';
import '@ionic/vue/css/typography.css';

/* Optional CSS utils that can be commented out */
import '@ionic/vue/css/padding.css';
import '@ionic/vue/css/float-elements.css';
import '@ionic/vue/css/text-alignment.css';
import '@ionic/vue/css/text-transformation.css';
import '@ionic/vue/css/flex-utils.css';
import '@ionic/vue/css/display.css';

/* Theme variables */
import './theme/variables.css';
import './theme/core.css';
import './theme/tailwind.css';
import {Storage} from "@ionic/storage";
import requestShifts from "./utils/requestShifts";
import requestUserdata from "./utils/requestUserdata";
import LitepieDatepicker from 'litepie-datepicker';

import {App as CapacitorApp} from '@capacitor/app' ;
import './registerServiceWorker'

const s = performance.now();

const app = createApp(App)
  .use(IonicVue)
   .use(store)
    .use(veProgress)

app.use(LitepieDatepicker)
.use(router);

CapacitorApp.addListener('appUrlOpen', function (event) {
  // Example url: https://beerswift.app/tabs/tabs2
  // slug = /tabs/tabs2
  const slug = event.url.split('.app').pop();

  // We only push to the route if there is a slug present
  if (slug) {
    router.push({
      path: slug,
    });
  }
});

const storage = new Storage()
storage.create().then((storageInstance) => {
  app.config.globalProperties.$ionicStorage = storageInstance
  app.config.globalProperties.$store.state.$ionicStorage = storageInstance

  storageInstance.get("applicationData").then(data => {
    console.log(data)
    if(!data || !data.jwt){
      app.config.globalProperties.$router.replace('/login');
      router.isReady().then(() => {
        app.mount('#app');
      });
      return;
    }

    const jwt = data.jwt;

    Axios.defaults.headers.common['Authorization'] = `Bearer ${jwt}`;
    app.config.globalProperties.$store.commit('updateJWT', jwt);
    app.config.globalProperties.$store.commit('updateActiveShiftId', data.activeShiftId);
    app.config.globalProperties.$store.commit('addMultipleShifts', data.shifts);
    app.config.globalProperties.$store.commit('updateUserdata', data.userData);

    console.log(performance.now() - s)

    router.isReady().then(() => {
      app.mount('#app');
    });
    requestShifts(app.config.globalProperties.$store.state.userRegion, app.config.globalProperties.$store, storageInstance)
    requestUserdata(app.config.globalProperties.$store, storageInstance)

  })
})



