<template>
  <ion-page>
    <ion-header>
      <ion-toolbar v-if="hasToolbar" mode="ios">
        <ion-buttons slot="start">
          <ion-back-button
              :default-href="pageDefaultBackLink"
          ></ion-back-button>
        </ion-buttons>
        <ion-title>{{ pageTitle }}</ion-title>
        <ion-buttons slot="end">
          <slot name="actions"></slot>
        </ion-buttons>
      </ion-toolbar>
    </ion-header>
    <ion-content v-bind:class="contentClass" ref="trigger">
      <slot ref="content"/>
    </ion-content>
  </ion-page>
</template>

<script>
import {
  IonPage,
  IonHeader,
  IonToolbar,
  IonTitle,
  IonContent,
  IonBackButton,
  IonButtons,
} from "@ionic/vue";

export default {
  props: {
            pageTitle: String,
            pageDefaultBackLink: String,
            hasBackground: {
              type: Boolean,
              default: false
            },
            hasToolbar: {
              type: Boolean,
              default: true
            },
            darkBackground: {
              type: Boolean,
              default: false
            },
        },
  components: {
    IonPage,
    IonHeader,
    IonToolbar,
    IonTitle,
    IonContent,
    IonBackButton,
    IonButtons,
  },
  computed:{
    contentClass(){
      return this.hasBackground ? this.backgroundClass : "";
    },
    backgroundClass(){
      return this.darkBackground ? "darkBackground" : "brightBackground"
    }
  },
  mounted() {


  }
};
</script>

<style>


</style>