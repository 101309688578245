import axios from "axios";

export default function requestUserdata(store, persistentStore, callback = () => {}){

    axios.get(`https://aarrow.app/development/secure/newApi/index.php/user/withEmail/${store.state.userEmail}/withRegion/${store.state.userRegion}`)
        .then(response => {
                let userData = response.data.payload;

                store.commit('updateUserdata', userData)

                persistentStore.get('applicationData').then(data => {
                    if (!data)
                        return;

                    data.userData = userData

                    persistentStore.set('applicationData', data);
                })
                callback();
            }
        )

}