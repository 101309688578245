<template>
  <base-layout v-bind:has-background="true" v-bind:has-toolbar="false" ref="wrapper">

      <ShiftArrow v-for="(shift,index) in shifts" v-bind:key="shift.id" :shift="shift" v-show="visibility[index]" />

  </base-layout>
</template>

<script>

import ShiftArrow from "./ShiftArrow";
import BaseLayout from "../base/BaseLayout";
import moment from "moment-timezone";

export default {
  name: "Anchor",
  props:{
    shiftType: {
      type: String,
      default: "Personal"
    },
    searchString: {
      type: String,
      default: ""
    },
    startDate:{
      type: String,
    },
    endDate:{
      type: String
    }

  },
  components:{
    BaseLayout,
    ShiftArrow
  },
  data(){
    return{

    }
  },
  computed:{
    userEmail(){
      return this.$store.state.userEmail;
    },
    userRegion(){
      return this.$store.state.userRegion;
    },
    startMoment(){
      return moment(this.startDate);
    },
    endMoment(){
      return moment(this.endDate);
    },
    todayShiftIndex(){
      if (!this.shifts.length){
        return -1;
      }
      const todayString = moment().tz(this.shifts[0].timezone).format("Y-MM-DD");
      return Math.max(0, this.shifts.findIndex((shift, index) => this.visibility[index] && shift.date === todayString));

    },
    shifts(){
      return this.$store.state.shifts.filter(shift => {
        const shiftMoment = moment(shift.date);
        return this.startMoment.diff(shiftMoment, 'days') <= 0 && this.endMoment.diff(shiftMoment, 'days') >= 0 && (shift.employeeEmail.toLowerCase() === this.userEmail.toLowerCase() || shift.employeeName.toLowerCase() === "");
      })
    },
    visibility(){
      console.log(this.shifts)
      return this.shifts.map(shift => {
        return !(this.shiftType !== "Personal" && (shift.employeeEmail.toLowerCase() === this.userEmail.toLowerCase())) &&
            (this.shiftType !== "Personal" || (shift.employeeEmail.toLowerCase() === this.userEmail.toLowerCase())) &&
                (!this.searchString || shift.location.toLowerCase().includes(this.searchString.toLowerCase()) ||
                    shift.employeeName.toLowerCase().includes(this.searchString.toLowerCase()) ||
                    moment(shift.date).format("MM/DD").toLowerCase().includes(this.searchString.toLowerCase()) );
      })

    }
  },
  methods:{

  },
  mounted() {

    setTimeout(() => {
      if(this.todayShiftIndex === -1)
        return
      this.$refs.wrapper.$el.children[1].children[this.todayShiftIndex].scrollIntoView(true)
    }, 200)


  },
  watch:{
    todayShiftIndex(val){
      setTimeout(() => {
        if(val === -1)
          return
        this.$refs.wrapper.$el.children[1].children[val].scrollIntoView(true)
      }, 0)
    }
  }

}
</script>

<style scoped>



</style>