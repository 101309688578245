<template>
  <base-layout page-title="Overview" v-bind:has-toolbar="false" v-bind:has-background="true" >
  <ion-grid ref="content">
    <ion-row style="height:18%;overflow: hidden;">
      <ion-col class = "centered">
        <ion-button style="height:100%;" color="light" @click="gotoShiftInfoPage()">
        <div>

          <h2 id="locationNameField" style="margin-bottom:3px;text-align: center;font-size:1.5em">{{shift.location ? shift.location : "Kein Auftrag"}}</h2>
          <h3 id="dateField" style="margin-top:5px;margin-bottom: 0px">{{

              dateFormatted
            }}</h3>
        </div>

        </ion-button>
        <!--
        <hr>
        <div>
        <h4 id="dateField" style="margin-top:5px;margin-bottom: 0px">Note: {{ shift.notes}}</h4>
        <hr>
        <h4 id="dateField" style="margin-top:5px;margin-bottom: 0px">Corner: {{ shift.corner}}</h4>

        </div>-->
      </ion-col>
    </ion-row>
    <ion-row style="height:10vh;width:100%; overflow:hidden">
        <ion-col style="width:20%; min-width: 20%;display: flex; justify-content: flex-end;" @click="previousImage">
          <ion-icon style="font-size:25px;position:relative; top:50%; transform: translateY(-50%);" :icon="chevronBackOutline"></ion-icon>
        </ion-col>
        <ion-col style="width:60%; min-width: 20%;">
          <img style="width:100%;height:100%;margin:auto; object-fit: scale-down" :src="clockInImage" @click="popupImage()">
        </ion-col>
        <ion-col style="width:20%;min-width: 20%;" >
          <ion-icon style="font-size:25px; position:relative; top:50%; transform: translateY(-50%);" :icon="chevronForwardOutline" @click="nextImage"></ion-icon>
        </ion-col>


        <ion-button color="success" style="float:right; position: absolute; right: 10%;top:21.5%; z-index:100" fill="clear" @click="addAdditionalPicture" v-bind:disabled="!isToday || shift.id === -1">
          <ion-img style="width:100%;height:100%;border-radius: 1000px; " :src="addCircleButton"></ion-img>
        </ion-button>

    </ion-row>

    <ion-row style="height:5vh; margin-bottom: 2%;">
      <ion-col class="centered" ref="circleContainer" style="height:100%;">
        <progress-circle v-bind:color="ringColor" v-bind:size="circleHeight" v-bind:progress="completedPercentage" v-bind:total="shiftDurationString" v-bind:completed="completedTimeString"></progress-circle>

      </ion-col>
    </ion-row>

    <ion-row  class="timesRow">
      <ion-col class="centered">
        <div class="grid-2 shift-specs">
          <div class="spec">
            <div class="title green">Schicht Start</div>
            <div id="startTimeField" class="data">{{ startTimeFormatted }}</div>
          </div>
          <div class="spec">
            <div class="title red">Schicht Ende</div>
            <div id="endTimeField" class="data">{{ endTimeFormatted }}</div>
          </div>
          <div class="spec">
            <div class="title blue">Pause Start</div>
            <div id="startTimeField" class="data">{{ breakStartTimeFormatted }}</div>
          </div>
          <div class="spec">
            <div class="title blue">Pause Ende</div>
            <div id="endTimeField" class="data">{{ breakEndTimeFormatted }}</div>
          </div>
          <div class="spec">
            <div class="title dark-grey">Check-In</div>
            <div id="clockInTimeField" class="data">{{ clockInTimeFormatted  }}</div>
          </div>
          <div class="spec">
            <div class="title dark-grey">Check-Out</div>
            <div id="clockOutTimeField" class="data">{{ clockOutTimeFormatted }}</div>
          </div>
        </div>
      </ion-col>
    </ion-row>

    <ion-row style="" >
      <div class="btn-row">

        <IonButton color="tertiary" class="lowerMiddleButton" type="button" @click="addNote" v-bind:disabled="!isToday || shift.id === -1">
          <ion-icon style="margin-right:10%" :icon="addCircle" ></ion-icon>
          <ion-label >Kommentar</ion-label>
        </IonButton>
        <IonButton color="success" class="lowerMiddleButton" type="button" @click="callManager" v-bind:disabled="!isToday || shift.id === -1">
          <ion-icon  :icon="call" ></ion-icon>
          <ion-label>Roman</ion-label>
        </IonButton>

      </div>
    </ion-row>
    <ion-row style="width:100%;">


    </ion-row>

    <ion-row style="flex: 1 1 auto; position: relative; overflow-x:hidden;">

      <ion-col  style="position: relative;width: 100%;">
        <ion-button class = "bottomButton" color="warning"  v-bind:disabled="!isRunning" @click=" !breakRunning ? gotoBreakStart() : gotoBreakEnd()">
          <ion-icon  :icon="alarmSharp" ></ion-icon>
          <ion-label v-if="!breakRunning"> Pause nehmen</ion-label>
          <ion-label v-else>End Break</ion-label>
        </ion-button>
      </ion-col>

    </ion-row>


    <ion-row style="flex: 1 1 auto; position: relative; overflow-x:hidden;">
      <ion-col style="position: relative;width: 100%;">
        <ion-button v-if="!isToday" class = "bottomButton" color="tertiary" disabled>
          <ion-icon  :icon="alarmSharp" ></ion-icon>
          <ion-label v-if="dateDiff === 1">Schicht startet morgen</ion-label>
          <ion-label v-else-if="dateDiff > 1">Schicht started in {{dateDiff}} Tagen</ion-label>
        </ion-button>
        <ion-button @click="startShift" v-else-if="!isStarted" class = "bottomButton" color="success" :disabled="shift.id === -1">
          <ion-icon  :icon="navigateCircle" ></ion-icon>
          <ion-label >Check-In</ion-label>
        </ion-button>
        <ion-button v-else-if="isRunning" class = "bottomButton" color="primary" @click="showClockOutAlert()">
          <ion-icon  :icon="closeCircleSharp" ></ion-icon>
          <ion-label >Check-Out</ion-label>
        </ion-button>
        <ion-button v-else-if="isStopped" class = "bottomButton" color="tertiary" disabled>
          <ion-icon  :icon="checkmarkSharp" ></ion-icon>
          <ion-label>Schicht beendet</ion-label>
        </ion-button>
      </ion-col>
    </ion-row>

  </ion-grid>
    <div class="modal" v-show="showImage">

      <!-- The Close Button -->
      <span class="close" @click="closeImage">&times;</span>

      <!-- Modal Content (The Image) -->
      <img class="modal-content" :src="clockInImage">

      <!-- Modal Caption (Image Text) -->
      <div id="caption"></div>
    </div>
  </base-layout>
</template>

<script>
import {
  IonGrid,
  IonRow,
  IonCol,
  IonButton,
  IonIcon,
  IonLabel,
  alertController,
  IonImg
} from "@ionic/vue"
import BaseLayout from "../base/BaseLayout";
import ProgressCircle from "./ProgressCircle";
import {
  addCircle,
  navigateCircle,
  closeCircleSharp,
  alarmSharp,
  checkmarkSharp,
  cameraSharp,
  videocamSharp,
  alertCircle,
  informationCircle,
  chevronBackOutline,
  chevronForwardOutline,
  call
} from 'ionicons/icons';
import {Camera, CameraResultType, CameraSource, CameraDirection} from "@capacitor/camera"
import * as momentUtils from "../../utils/momentUtils";
import {registerPlugin} from "@capacitor/core";
import {dateToAmericanFormat} from "../../utils/momentUtils";
import { FilePicker } from '@capawesome/capacitor-file-picker';

const BackgroundGeolocation = registerPlugin("AArrowBackgroundGeolocation");

import moment from "moment-timezone";
import axios from "axios";
import PullToRefresh from "pulltorefreshjs";
import requestShifts from "../../utils/requestShifts";
import addCircleButton from "../../assets/tempImages/addCircle.png"
import Swal from "sweetalert2";
export default {
  name: "Overview",
  components: {ProgressCircle, BaseLayout, IonGrid, IonRow, IonCol, IonButton, IonIcon, IonLabel, IonImg},
  data() {
    return {

      circleHeight: window.innerHeight * 0.18,
      completedTime: 0,
      clockInImage: "https://aarrow.app/development/secure/images/JohnnyAarrow.jpg",
      showImage: false
    }

  },
  setup(){
    return{
      addCircle,
      navigateCircle,
      closeCircleSharp,
      alarmSharp,
      checkmarkSharp,
      cameraSharp,
      videocamSharp,
      alertCircle,
      informationCircle,
      chevronBackOutline,
      chevronForwardOutline,
      call,
      addCircleButton
    }
  },
  computed: {
    windowHeight(){
      return window.innerHeight
    },
    id(){
      return !this.$route.params.id || this.$route.params.id === '-1' ? this.$store.state.activeShiftId : this.$route.params.id
    },
    shift() {
      return this.$store.state.shifts.find(s => s.id === this.id) || this.$store.state.defaultShift ;
    },
    dateFormatted(){
      return dateToAmericanFormat(this.shift.date)
    },
    dateDiff(){
      return Math.floor(moment.duration(moment(this.shift.date).diff(moment().tz(this.shift.timezone).format("YYYY-MM-DD"))).asDays());
    },
    isToday(){
      return this.dateDiff === 0;
    },
    breaks(){
      return this.shift.breaks;
    },
    breakRunning(){
      return this.breaks && this.breaks.length && this.breaks.some(breakObject => !breakObject["end_time"]);
    },
    isStarted(){
      return this.shift.clockInTime && this.shift.clockInTime !== "00:00:00";
    },
    isStopped(){
      return this.shift.clockOutTime && this.shift.clockOutTime !== "00:00:00";
    },
    isRunning(){
      return this.isStarted !== this.isStopped;
    },
    shiftDuration(){
      return momentUtils.millisecondsBetweenTimes(this.shift.startTime, this.shift.endTime);
    },
    shiftDurationString(){
      return momentUtils.millisecondsDifferenceToHoursMinuteSecondString(this.shiftDuration);
    },
    completedTimeString(){
      return momentUtils.millisecondsDifferenceToHoursMinuteSecondString(this.completedTime)
    },
    completedPercentage(){
      return Math.min(100, this.completedTime * 100 / Math.max(this.shiftDuration, 1));
    },
    ringColor(){
      return getComputedStyle(document.documentElement).getPropertyValue('--ion-color-success')
    },
    startTimeFormatted(){
      return momentUtils.timeToAmPmFormat(this.shift.startTime);
    },
    endTimeFormatted(){
          return momentUtils.timeToAmPmFormat(this.shift.endTime);
      },
    breakStartTimeFormatted(){
      return this.shift.location !== "" ? momentUtils.timeToAmPmFormat(this.shift.breakStartTime) : "-";
    },
    breakEndTimeFormatted(){
      return this.shift.location !== "" ? momentUtils.timeToAmPmFormat(this.shift.breakEndTime) : "-";
    },
    clockInTimeFormatted(){
          return momentUtils.timeToAmPmFormat(this.shift.clockInTime);
      },
    clockOutTimeFormatted(){
          return momentUtils.timeToAmPmFormat(this.shift.clockOutTime);
      },
    importantShiftInfo(){
      return this.shift.notes || this.shift.transportation
    },
    photos()
    {
      return this.shift.photos ? this.shift.photos : []
    }
  },methods:{

    callManager(){
      window.open(`tel: 004915226688555`)
    },


    async checkGPSEnabledAndPermissions(successCallback){

      await BackgroundGeolocation.requestPermissions()

      const checkResult = await BackgroundGeolocation.gpsEnabledAndPermissionsGiven()
      console.log(checkResult)

      if (!checkResult.success){
        this.gpsCheckFailure(checkResult, true)
        return;
      }

      successCallback()

    },


    gotoShiftInfoPage(){
      this.$router.push(`/tabs/schedule/${this.id}`);
    },

    loadShiftFromServer(){
      axios.get(`https://aarrow.app/development/secure/newApi/index.php/shift/id/${this.id}`)
          .then(response => {
            this.$store.commit('addShift', response.data.payload)
          });
    },
    gotoBreakStart(){
      axios.put("https://aarrow.app/development/secure/newApi/index.php/trackingShift/break/start", {id: this.shift.id}).then(response => {

        if (!response.data || !response.data.success)
          return //TODO inform user that something went wrong

        this.shift.breaks.push({shift_id: this.shift.id, start_time: response.data.payload.breakStart});
        this.$router.push("/tabs/clocking/breaks/-1")

      });

    },
    gotoBreakEnd(){
      axios.put("https://aarrow.app/development/secure/newApi/index.php/trackingShift/break/end", {id: this.shift.id}).then(response => {

        if(!response.data || !response.data.success)
          return //TODO inform user that something went wrong

        this.shift.breaks.find(breakObject => !breakObject["end_time"])["end_time"] = response.data.payload.breakEnd;

      });

    },
    startShift(){
      this.goodPictureReminder(() => this.takePicture(this.startPictureReceived))
    },

    goodPictureReminder(callback){
      Swal.fire({
        title: 'Check-In Foto!',
        html: "<span>Stelle sicher dass das Foto folgendes enthält: <br>" +
            "1. Dein Gesicht<br>" +
            "2. Dein Sign   &zwnj;<br>" +
            "3. Den Laden<br>",
        imageUrl: 'https://aarrow.app/development/secure/userUploads/shiftPhotos/54703-1674320314-.jpeg',
        imageWidth: 150,
        imageHeight: 200,
        imageAlt: 'Custom image',
        confirmButtonText: "Verstanden!",
        backdrop: false
      }).then((result) => {
        /* Read more about isConfirmed, isDenied below */
        if (result.isConfirmed) {
          callback()
        }
      })
    },

    startPictureReceived(image){

      this.requestBackgroundTrackingStart();
      this.uploadPicture(image)

    },
    async addPicture(callback){
      this.$store.commit('updateLock', true);
      const image = await Camera.getPhoto({
        quality: 100,
        resultType: CameraResultType.Base64,
      });
      callback(image)
    },
    async addMedia(callback){
      this.$store.commit('updateLock', true);
      const media = await FilePicker.pickMedia({
          multiple: true,
        });
      callback(media)
    },

    uploadMedia(media){
      console.log(media)
      const formData = new FormData();
      for (let file of media.files){
        formData.append(file.name, file.blob);
      }
      formData.append('id', this.shift.id);
      axios.post("https://aarrow.app/development/secure/newApi/index.php/trackingShift/media", formData, {
        headers: {
          'Content-Type': 'multipart/form-data',
        },
      })




    },

    uploadPicture(image){
      axios.post("https://aarrow.app/development/secure/newApi/index.php/trackingShift/photo", {id: this.shift.id,  imageData: image}).then(response => {


        if(!response.data || !response.data.success)
          return //TODO inform user that something went wrong

        this.loadImages()

      })

    },
    uploadNote(note){

      axios.post("https://aarrow.app/development/secure/newApi/index.php/trackingShift/note", {id: this.shift.id,  note: note})

    },
    async takePicture (callback) {
      this.$store.commit('updateLock', true);
      const image = await Camera.getPhoto({
        quality: 100,
        source: CameraSource.Camera,
        resultType: CameraResultType.Base64,
        direction: CameraDirection.Front
      });
      //const data = "data:image/png;base64," + image.base64String;
      callback(image)

      // Can be set to the src of an image now
    },
    requestBackgroundTrackingStart(){
        this.backgroundTrackingStarted()
    },
    backgroundTrackingStarted(){

      if (!this.isRunning){
        this.startShiftServerRequest()
      }
    },
    startShiftServerRequest(){
      axios.patch("https://aarrow.app/development/secure/newApi/index.php/trackingShift/start", {id: this.shift.id}).then(response => {

        if(!response.data || !response.data.success)
          return //TODO inform user that something went wrong

        this.completedTime = 0;
        this.shift.clockInTime = response.data.payload.startTime;
        this.shift.status = response.data.payload.status;
        this.manageClock();
        this.$store.commit('updateLock', false);

      })
    },
    endShift(){

      axios.patch("https://aarrow.app/development/secure/newApi/index.php/trackingShift/stop", {id: this.shift.id}).then(response => {

        if(!response.data || !response.data.success)
          return //TODO inform user that something went wrong

        this.shift.clockOutTime = response.data.payload.stopTime;
        this.shift.status = response.data.payload.status;
        this.$router.push(`/tabs/clocking/sign-out/${this.shift.id}`)

      })
    },

    manageClock() {
      this.updateCompletedTime();
      if (this.isRunning) {
        setTimeout(this.manageClock, 250);
      }
    },
    updateCompletedTime(){

      if (this.isStarted && this.isStopped)
        this.completedTime = momentUtils.millisecondsBetweenTimes(this.shift.clockInTime, this.shift.clockOutTime);
      else if (this.isRunning)
        this.completedTime = momentUtils.millisecondsBetweenTimes(this.shift.clockInTime, moment().tz(this.shift.timezone).format("HH:mm:ss"));
      else
        this.completedTime = 0

    },
    addAdditionalPicture(){

        this.addMedia(media => this.uploadMedia(media))

        //this.addPicture(img => this.uploadPicture(img));

    },


    addNote(){
      return alertController
          .create({
            header: 'Kommentar hinzufügen',
            inputs: [
              {
                placeholder: 'Kommentar',
                name: 'note'
              },
            ],
            buttons: [
              {
                text: 'Abbrechen',
                role: 'cancel',
                cssClass: 'secondary',
                handler: () => {
                  console.log('Confirm Cancel')
                },
              },
              {
                text: 'Ok',
                handler: (note) => {
                  this.uploadNote(note.note);
                },
              },
            ],
          })
          .then(a => a.present())
    },

    async showClockOutAlert() {
      const alert = await alertController
          .create({
            header: 'Bestätigen',
            message: 'Willst du deine Schicht beenden? Du wirst dich danach nicht wieder einloggen können.',
            buttons: [
              {
                text: 'Nein',
                role: 'cancel',
                handler(){

                },
              },
              {
                text: 'Ja',
                handler: () => {
                  this.endShift()
                },
              },
            ],
          });
      return alert.present();
    },

    loadImages(){
      const shiftIds = [this.shift.id]

      axios.get(`https://aarrow.app/development/secure/newApi/index.php/shift/photos/${JSON.stringify(shiftIds)}`).then(data => {
            const payload = data.data.payload;
        if (this.id && this.id !== -1){
          const photos = [payload[this.id]].flat()
          if (photos.length){
            console.log("photos added")
            this.shift.photos = photos;
            console.log(this.shift.photos)
            this.clockInImage = photos[0].path;
          }else{
            this.clockInImage = "https://aarrow.app/development/secure/images/JohnnyAarrow.jpg"
          }

        }
        }

      );
    },

    nextImage(){

      if(!this.shift.photos)
        return;

      let photoIndex = this.shift.photos.findIndex(photo => photo.path === this.clockInImage);

      let newIndex = (photoIndex + 1) % this.shift.photos.length;

      this.clockInImage = this.shift.photos[newIndex].path;

    },

    previousImage(){


      if(!this.shift.photos)
        return;

      let photoIndex = this.shift.photos.findIndex(photo => photo.path === this.clockInImage);

      let newIndex = (((photoIndex - 1) % this.shift.photos.length) + this.shift.photos.length) % this.shift.photos.length;

      this.clockInImage = this.shift.photos[newIndex].path;

    },

    popupImage(){
      this.showImage = true
    },

    closeImage(){
      this.showImage = false
    },
    showRegions() {

      axios.get(`https://aarrow.app/development/secure/newApi/index.php/user/regions/${this.$store.state.userEmail}`)
          .then(response => {
            this.regions = response.data.payload;
            setTimeout(() => {
              this.$refs.regionSelect.$el.click();
              this.showRegionSelect = false;
            }, 0)
          });
    },
  },
  mounted() {
    this.manageClock();
    this.loadImages()
    const mainElement = this.$refs.content.$el;
    const store = this.$store
    PullToRefresh.init({
      mainElement: mainElement,
      triggerElement: mainElement,
      onRefresh() {
        requestShifts(store.state.userRegion, store, store.state.$ionicStorage);
      }
    });

    if (this.isRunning && !this.$store.state.locationWatchers.length)
        this.requestBackgroundTrackingStart()

  },
  watch:{
    isRunning(newValue){
      if (newValue)
        this.manageClock();
    },
    id(){
      this.updateCompletedTime()
      this.loadImages()

    }
  }


}
</script>

<style scoped>



ion-grid{
  width: 90vw;
  height:90%;
  margin-top: 5%;
  background: white;
  display: flex;
  flex-flow: column;
}

ion-row:first-child{
  display: flex;
  align-items: center;
  justify-content: center;
}

.centered{
  display: flex;
  align-items: center;
  justify-content: center;
  flex-flow: wrap;
}

hr{
  flex-basis: 100%; height: 0; margin: 0; border: 0;
}

.grid-2{display: flex;flex-wrap: wrap;justify-content: center;row-gap: 2vh;gap: 10px;}

.grid-2 .spec{

  width: calc(50% - 20px);
}

 .spec .title.blue{background-color: #007FF2!important;}
 .spec .title.red{background-color: #E7262B;}
 .spec .title.green, td.green{background-color: #3D9846;}
 .spec .title.dark-grey{background-color: #696969;}

.spec .title, .title{
  color: #fff;
}

.spec, .aarrow-table tr:first-child{text-align: center;max-width: 50%;}

.data{
  background-color: #E2E2E2;
  margin: 0 10px;
  font-size: 1em;
  color: #4e4e4e;
}


.middleButton{
}

.centerButton{

}

.middleButton > ion-label{
  margin-left: 5%;
}

.bottomButton{
  width: 100%;
  bottom: 0px;
  left: 0;
  position: absolute;
  padding-right:  5px;
}

.bottomButton > ion-label{
  margin-left: 5%;
}

.timesRow{
  height:33%;
}
.btn-row {
  display: flex;
  gap: 10px;
  margin: 5px 0;
  flex-wrap: wrap;
  position: relative;
  align-items: center;
  width:100%;
}

.btn-row ion-button{
  flex-basis: calc(50% - 10px);
}




@media (max-height: 715px) {
  .btn-row{
    flex-wrap: nowrap;
  }
  .btn-row ion-button{
    font-size: small;
  }
  .btn-row ion-button:first-child {
    flex-basis: fit-content;
  }
}

/* The Modal (background) */
.modal {
  position: fixed; /* Stay in place */
  z-index: 1; /* Sit on top */
  padding-top: 100px; /* Location of the box */
  left: 0;
  top: 0;
  width: 100%; /* Full width */
  height: 100%; /* Full height */
  overflow: auto; /* Enable scroll if needed */
  background-color: rgb(0,0,0); /* Fallback color */
  background-color: rgba(0,0,0,0.9); /* Black w/ opacity */
}

/* Modal Content (Image) */
.modal-content {
  margin: auto;
  display: block;
  width: 80%;
  max-width: 700px;
}

.close {
  position: absolute;
  top: 15px;
  right: 35px;
  color: #f1f1f1;
  font-size: 40px;
  font-weight: bold;
  transition: 0.3s;
}


</style>