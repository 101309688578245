<template>
  <div class="container">
    <div class="progress-bar__container">
      <div class="progress-bar" :style="{left: -100 + progress + '%', backgroundColor:color}">
      </div>
    </div>
    <span class="progress-bar__text" style="position: absolute">{{completed}} / {{total}}</span>
  </div>

  <!--<ve-progress
      v-bind:progress="progress" v-bind:size="size" v-bind:fontSize="circleFontSize" v-bind:color="color" >
      <p id ="completedDurationField" style="margin-block-end: 0.5em">{{ completed }}</p>
    <hr>
      <p id ="shiftDurationField" style="margin-block-start: 0.5em">{{ total }}</p>
  </ve-progress>-->

</template>

<script>



export default {
  name: "ProgressCircle",
  props: ['size', 'progress', 'color', 'completed', 'total'],
  computed: {
    circumference() {
      return this.radius * 2 * Math.PI
    },
    offset() {
      return this.circumference - this.fillPercentage / 100 * this.circumference
    },
    diameter() {
      return this.radius * 2 + 15;
    },
    transformOrigin() {
      return (+this.radius + 10) + 'px'
    },
    height() {
      return this.diameter + 'px';
    },
    progressRingStyle() {
      return {
        height: this.height
      }
    },
    filledCircleStyle() {
      return {
        stroke: this.color,
        transformOrigin: this.transformOrigin + ' 0px',
        strokeDasharray: `${this.circumference} ${this.circumference}`,
        strokeDashoffset: this.offset
      }
    },
    circleStyle() {
      return {
        transformOrigin: this.transformOrigin + ' 0px',
      }
    },
    circleFontSize(){
        return (this.size / 7) + 'px'
    }
  }
}
</script>

<style scoped>
hr {
  border: 0;
  clear:both;
  display:block;
  width: 60vw;
  background-color: black;
  height: 1px;
}

.container {
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100%;
}

.progress-bar__container {
  width: 80%;
  height: 2rem;
  border-radius: 0.5rem;
  position: relative;
  overflow: hidden;
  transition: all 0.5s;
  will-change: transform;
  box-shadow: 0 0 5px #e76f51;
}


.progress-bar {
  position: absolute;
  height: 100%;
  width: 100%;
  content: "";
  top:0;
  bottom: 0;
  left: -100%;
  border-radius: inherit;
  display: flex;
  justify-content: center;
  align-items:center;
  color: white;
  font-family: sans-serif;
}



</style>

