import {createStore} from "vuex";
import jwtDecode from "jwt-decode";

export default createStore({
    state () {
        return {
            defaultShift:{
                id: -1,
                timestamp: 0,
                startTime: "00:00:00",
                endTime: "00:00:00",
                clockInTime: "00:00:00",
                clockOutTime: "00:00:00",
                breakStartTime: "00:00:00",
                breakEndTime: "00:00:00",
                timezone: 'America/Los_Angeles',
                photos: []},
            shifts: [],
            locations: [],
            scheduleState:{
                shiftType: "Personal",
                searchString : ""
            },
            jwt: '',
            userEmail: '',
            userRegion: '',
            isManager: false,
            activeShiftId: -1,
            updateLock: false,
            locationWatchers: [],
            userData: {firstName: "",
                lastName: "",
                street: "",
                city: "",
                zip: "",
                state: "",
                phone: "",
                carrier: "",
                monday: false,
                tuesday: false,
                wednesday: false,
                thursday: false,
                friday: false,
                saturday: false,
                sunday: false
            }

        }
    },
    mutations: {
        updateJWT(state, jwt){
            const decoded = jwtDecode(jwt);

            state.jwt = jwt;

            state.userEmail = decoded.email;
            state.userRegion = decoded.region;
            state.isManager = decoded.manager;


        },
        addShift(state, shift){

            if (!shift)
                return

            const shiftIndex = state.shifts.findIndex(s => s.id === shift.id);

            if (shiftIndex === -1)
                state.shifts.push(shift);
            else
                state.shifts.splice(shiftIndex, 1, shift)

            state.shifts = state.shifts.sort((a,b) => Math.sign(new Date(b.date) - new Date(a.date)));


        },
        addMultipleShifts(state, shifts){

            if (!shifts.length)
                return

            state.shifts = state.shifts.map(s => {s.deletionPending = true; return s});
            shifts = shifts.map(s => {s.deletionPending = false; return s});

            let insertionIndexes = [];
            state.shifts.forEach((shift, index) => {
                insertionIndexes[shift.id] = index;
            })


            shifts = shifts.filter(shift => {

                const shiftIndex = insertionIndexes[shift.id];
                if (shiftIndex === null || shiftIndex === undefined)
                    return true;

                state.shifts.splice(shiftIndex, 1, shift)

                return false;
            })

            shifts.forEach(shift => {
                state.shifts.push(shift);
            })

            state.shifts = state.shifts.filter(s => {
                if (s.deletionPending && state.activeShiftId === s.id){
                    state.activeShiftId = state.defaultShift.id;
                }

                return !s.deletionPending;
            });

            state.shifts = state.shifts.sort((a,b) => {
                try {
                    return Math.sign(new Date(b.date) - new Date(a.date))
                } catch (e) {
                    return -1;
                }

            });


        },
        clearShifts(state){

            state.shifts = [];

        },
        updateActiveShiftId(state, id){

            this.state.activeShiftId = id;

        },
        updateShift(state, updateObject){

            const shiftIndex = state.shifts.findIndex(s => s.id === updateObject.id);
            state.shifts[shiftIndex][updateObject.field] = updateObject.val;

        },

        updateSearchString(state, searchString){

            state.scheduleState.searchString = searchString;

        },
        updateShiftType(state, shiftType){

            state.scheduleState.shiftType = shiftType;

        },
        updateLock(state, lockState){
            state.updateLock = lockState;
        },
        addWatcher(state, id){
            state.locationWatchers.push(id)
        },
        removeWatcher(state, id){
            if (state.locationWatchers.includes(id))
                state.locationWatchers.splice(state.locationWatchers.findIndex(w => w === id), 1)
        },
        clearWatcher(state){
            state.locationWatchers = [];
        },

        updateLocations(state, locations){
            state.locations = locations;
        },
        updateUserdata(state, userData){
            if(!userData) return;
            state.userData = userData;

            state.isManager = ["Account Manager", "Account Executive", "Assistant Manager", "General Manager", "Regional Manager", "Owner"].includes(userData.role)

        }

    }
});