import { createRouter } from '@ionic/vue-router';
import Tabs from '../views/Tabs.vue'
import Overview from "../components/ClockingTab/Overview";
import TabSchedule from "../views/TabSchedule";
import TabOther from "../views/TabOther";
import {createWebHashHistory} from "vue-router";

const routes = [
  {
    path: '/',
    redirect: '/tabs/clocking'
  },
  {
    path: '/login',
    name: "Login",
    component: () => import('../views/Login.vue'),
  },  {
    path: '/login/link/:token',
    name: "LoginLink",
    component: () => import('../views/LoginLink.vue'),
  },
  {
    path: '/actions',
    name: "Actions",
    component: () => import('../views/Actions.vue'),
  },
  {
    path: '/intro',
    name: "Intro",
    component: () => import('../views/Intro.vue'),
  },
  {
    path: '/create',
    name: "Create",
    component: () => import('../views/CreateShift'),
  },

  {
    path: '/tabs/',
    component: Tabs,
    children: [
      {
        path: 'feedback/:id',
        component: () => import('../components/ClockingTab/Feedback.vue'),
      },
      {
        path: '',
        redirect: '/tabs/schedule'
      },
      {
        path: 'profile',
        component: () => import('../views/TabProfile.vue')
      },
      {
        path: 'other',
        component: TabOther
      },
      {
        path: 'clocking/',
        component: () => import('../views/TabClocking.vue'),
        children: [
          {
            path: '',
            redirect: '/tabs/clocking/overview/-1'
          },
          {
            path: 'overview/:id',
            name: "Overview",
            component: Overview,
          },
          {
            path: 'breaks/:id',
            component: () => import('../components/ClockingTab/Breaks'),
          },
          {
            path: 'review',
            component: () => import('../components/ClockingTab/Review'),
          },
          {
            path: 'switch/:id',
            component: () => import('../components/ClockingTab/ChangeShift'),
          },
          {
            path: 'sign-out/:id',
            component: () => import('../components/ClockingTab/SignOut'),
          },

        ]
      },
      {
        path: 'schedule',
        name: 'ScheduleTab',
        component: TabSchedule,
      },
      {
        path: 'schedule/:id',
        component: () => import('../components/ScheduleTab/ShiftInfo.vue')
      },
      {
        path: 'schedule/edit/:id',
        component: () => import('../components/ScheduleTab/ShiftEdit.vue')
      },
      {
        path: 'review/:id',
        component: () => import('../components/ClockingTab/ReviewDetail'),
      },{
        path: 'drive/corner',
        component: () => import('../components/DriveComponents/CornerSpinning'),
      },{
        path: 'drive/coaching',
        component: () => import('../components/DriveComponents/CoachingSpinning'),
      },{
        path: 'drive/highlight',
        component: () => import('../components/DriveComponents/Highlight'),
      },{
        path: 'drive/wssc',
        component: () => import('../components/DriveComponents/WSSC'),
      },{
        path: 'drive/youtube',
        component: () => import('../components/DriveComponents/YouTube'),
      },{
        path: 'drive/spinformation',
        component: () => import('../components/DriveComponents/SpinFormation'),
      },
      {
        path: 'external/audit',
        component: () => import('../components/ExternalComponents/Audits'),
      },
      {
        path: 'external/inbox',
        component: () => import('../components/ExternalComponents/Inbox'),
      },
      {
        path: 'external/inboxSettings',
        component: () => import('../components/ExternalComponents/InboxSettings'),
      },
      {
        path: 'external/auditReview',
        component: () => import('../components/ExternalComponents/AuditReview'),
      },
      {
        name: "AuditInfo",
        path: 'external/auditInfo',
        component: () => import('../components/ExternalComponents/AuditInfo'),
        props: true
      },
      {
        path: 'external/leads',
        component: () => import('../components/ExternalComponents/Leads'),
      },
      {
        path: 'external/recruits',
        component: () => import('../components/ExternalComponents/Recruits'),
      },
      {
        path: 'external/map',
        component: () => import('../components/ExternalComponents/ShiftViewer'),
      },
      {
        path: 'external/mw',
        component: () => import('../components/ExternalComponents/MWs'),
      },
      {
        path: 'external/finances',
        component: () => import('../components/ExternalComponents/Finances'),
      },
    ]
  }
]

const router = createRouter({
  history: createWebHashHistory(process.env.BASE_URL), // eslint-disable-line
  routes
})

export default router
